import React, {useEffect} from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';
import * as palette from '../styles/variables';
// import SilenIcon from './icons/silenIcon'
import BellIcon from './icons/bellIcon'
import { device } from '../styles/device'




export const NavList = styled.ul`
    margin: 0;
    align-items: baseline;

    li {
        list-style-type: none;
        cursor: pointer;

        a {
            text-decoration: none;
            display: block;
        }

        &.icon {

            a.fraud {
                display: flex;
                align-items: center;

                &.active {
                    svg.nav {
                        animation: none;
                    }
                }


                svg.nav {
                    width: 20px;
                    margin-left: 10px;

                    path.bell {
                        fill: ${palette.white};
                        stroke: ${palette.redDanger};
                        stroke-width: 20px;
                        transition: all 0.2s ease-in-out;
                        animation: pulsate 1s linear infinite;
                    }

                    path.bell-ball {
                        fill: ${palette.redDanger};
                    }
                }
            }
        }

        &.visited {

            &.icon {

                a.fraud {

                    svg.nav path.bell{
                        animation: none;
                    }
                }
            }

        }
    }

    @keyframes pulsate {
        /* 0% { transform: scale(1); }
        50% { transform: scale(1.3); }
        100% { transform: scale(1); } */
        0% {
            -webkit-transform: rotateZ(-8deg);
            transform: rotateZ(-8deg);
        }
        50% {
            -webkit-transform: rotateZ(8deg);
            transform: rotateZ(8deg);
        }
        100%{
            transform: rotateZ(-8deg);
            -webkit-transform: rotateZ(-8deg);
        }

    }
`;


// const activeStyles = {
//     color: palette.white
// }

const Nav = () => {
    useEffect(() => {
        const div = document.getElementById('navAlert')
        let fraudPageStorage = localStorage.getItem("fraudPage");

        if(!fraudPageStorage) {
            div.classList.remove('visited')
        } else {
          div.classList.add('visited')
        }

        return () => {
        };
    });

    return (
        <NavList>
            <li className="navItem">
                <Link to="/" activeClassName="active">Home</Link>
            </li>
            <li className="navItem icon" id="navAlert">
                <Link to="/fraud-alert/" activeClassName="active" className="fraud">Fraud & Infringement <BellIcon name="nav"/></Link>
            </li>
            <li className="navItem">
                <Link to='/profile/' activeClassName="active">Company Profile</Link>
            </li>

            <li className="navItem">
                <Link to='/products/' activeClassName="active">Products</Link>
            </li>

            <li className="navItem">
                <Link to='/contact/' activeClassName="active">Contact</Link>
            </li>
      </NavList>
    )
}

export default Nav