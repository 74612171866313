import React from "react"


const LogoSavie = ({name}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	            width="200px" height="50px" viewBox="0 0 406 157" className={name}>
                <g className="savie">
                <path fill="#FFFFFF" d="M39.38,79.57c-2.61,0-4.75,0.69-6.42,2.08c-1.67,1.39-2.51,3.38-2.51,5.99c0,2.61,0.77,4.71,2.32,6.3
                    c1.55,1.59,3.56,2.81,6.05,3.67c2.48,0.86,5.26,1.67,8.31,2.45c3.06,0.78,6.09,1.71,9.11,2.81c3.01,1.1,5.76,2.47,8.25,4.1
                    c2.48,1.63,4.5,3.93,6.05,6.91c1.55,2.98,2.32,6.54,2.32,10.7c0,7.25-2.96,13.47-8.86,18.64c-5.91,5.18-13.69,7.76-23.35,7.76
                    s-17.44-2.32-23.35-6.97c-5.91-4.65-8.86-11.25-8.86-19.8H30.7c0.57,6.84,4.07,10.27,10.51,10.27c3.01,0,5.38-0.79,7.09-2.38
                    c1.71-1.59,2.57-3.61,2.57-6.05c0-2.44-0.77-4.44-2.32-5.99c-1.55-1.55-3.57-2.77-6.05-3.67c-2.49-0.9-5.26-1.73-8.31-2.51
                    c-3.06-0.77-6.09-1.73-9.11-2.87c-3.02-1.14-5.77-2.51-8.25-4.09c-2.49-1.59-4.5-3.87-6.05-6.85c-1.55-2.97-2.32-6.5-2.32-10.57
                    c0-7.99,2.97-14.38,8.92-19.19c5.95-4.81,13.55-7.21,22.8-7.21c9.25,0,16.73,2.1,22.43,6.3c5.7,4.2,8.68,10.82,8.92,19.87H48.79
                    c-0.33-3.1-1.33-5.48-3-7.15C44.12,80.41,41.98,79.57,39.38,79.57z"/>
                <path fill="#FFFFFF" d="M141.75,150.1l-4.77-14.43h-30.81l-4.77,14.43H79.16l29.83-86.06h25.43l29.71,86.06H141.75z M111.43,119.79
                    h20.29l-10.15-30.56L111.43,119.79z"/>
                <path fill="#FFFFFF" d="M186.86,150.1l-30.44-85.82h22.25l21.27,65.4l21.27-65.4h22.25l-30.44,85.82H186.86z"/>
                <path fill="#FFFFFF" d="M253.48,150.1V64.29h20.9v85.82H253.48z"/>
                <path fill="#FFFFFF" d="M344.68,64.04v15.89h-31.05v18.46h27.38v15.89h-27.38v19.92h31.05v15.89h-51.96V64.04H344.68z"/>
                </g>
                <g className="symbol">
                <path fill="#FFFFFF" d="M399.39,18.53c0,0-3.08,0.46-6.95,0.46c-3.19,0-6.91-0.31-9.89-1.46c-7.77-3-12.52-11-12.52-11
                    s-4.75,8-12.52,11c-2.97,1.15-6.7,1.46-9.88,1.46c-3.87,0-6.95-0.46-6.95-0.46s-0.22,42.5,29.35,50.5
                    C399.6,61.03,399.39,18.53,399.39,18.53z M392.49,39.63c-4.32,13.62-11.87,22.12-22.45,25.29c-10.58-3.17-18.13-11.67-22.45-25.29
                    c-2.06-6.5-2.89-12.75-3.22-16.73c0.98,0.06,2.09,0.1,3.27,0.1c4.42,0,8.11-0.56,10.98-1.67c5.08-1.96,8.99-5.73,11.43-8.63
                    c2.43,2.9,6.34,6.67,11.42,8.63c2.87,1.11,6.56,1.67,10.98,1.67c1.18,0,2.29-0.04,3.27-0.1C395.38,26.88,394.55,33.13,392.49,39.63
                    z"/>
                <polygon fill="#FFFFFF" points="374.95,33.09 374.95,24.28 365.13,24.28 365.13,33.09 354.77,33.09 354.77,44.47 365.13,44.47
                    365.13,53.28 374.95,53.28 374.95,44.47 385.31,44.47 385.31,33.09 	"/>
                </g>
            </svg>

    )
}

export default LogoSavie
