import styled from 'styled-components'
import { device, width } from '../styles/device';
import * as palette from '../styles/variables'




export const MarketingPopupStyled = styled.div`



`;


