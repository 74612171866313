import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device } from '../styles/device'
import {TitleWithTextStyled} from './titleWithTextStyled'



export const ModalPopupStyled = styled.div`
        &.hide {
            display: none;
        }

        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgb(0,0,0);
        background: rgba(0,0,0,.8);
        overflow: auto;
        z-index: 6;

        .image-head-wrapper {
            max-width: 200px;
            margin: 0 auto;

            @media ${device.tablet} {
                /* margin-top: -100px; */
            }
        }

        .modal-sandbox {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: transparent;
        }

        .modal-box {
            position: relative;
            width: 98%;
            max-width: 620px;
            margin: 20px auto;
            animation-name: modalbox;
            animation-duration: .4s;
            animation-timing-function: cubic-bezier(0,0,.3,1.6);

            @media ${device.tablet} {
                margin: 100px auto;
            }
        }

        .modal-header {
            padding: 20px;
            background-color: ${palette.white};
            display: flex;
            flex-direction: column;

            @media ${device.tablet} {
                padding: 20px 40px;
            }
        }

        .closeWrapper {
            cursor: pointer;
            align-self: flex-end;

            .close-modal {
                width: 15px;
                height: 15px;

                path {
                    fill: #000;
                }
            }

        }

        .modal-body {
            background: ${palette.white};
            padding: 0px 20px 50px 20px;

            @media ${device.tablet} {
                padding: 0px 40px 50px 40px;
            }

            .fraud-alert-title {
                .container {
                    .title {
                        color: #b31010;
                        font-weight: 900;
                        text-decoration: underline;
                    }
                }
            }

            .body {
                .container {
                    .title {
                        color: #000;
                        font-size: 18px;
                    }
                }
            }
        }

        .doNotShowWrapper {
            text-align: center;
            margin-bottom: 1rem;
            font-size: .9rem;
        }

        .btnWrapper {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 0;

            @media ${device.tablet} {
                margin-right: 1rem;
                flex-direction: row;
            }
        }

        button.btn {
            color: ${palette.black};
            cursor: pointer;
            padding: 5px 40px;
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid ${palette.lightText};
            transition: all .2s ease;

            &:hover {
                background-color: ${palette.lightBg};
            }

            &.read-more {
                margin-right: 0;
                background-color: ${palette.cta};
                margin-bottom: 1rem;
                border-color: ${palette.cta};

                @media ${device.tablet} {
                    margin-right: 1rem;
                    margin-bottom: 0;
                }

                &:hover {
                    background-color: ${palette.ctaLight};
                }

                .text {
                    color: ${palette.white};
                    text-decoration: none;
                }
            }
        }

        @keyframes modalbox {
            0% {
                top: -250px;
                opacity: 0;
            }
            100% {
                top: 0;
                opacity: 1;
            }
        }

        &.scam-alert {
            .modal-header.headImage {
                padding: 30px 0 30px 0;
                position: relative;

                .closeWrapper {
                    z-index: 2;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }

            }

            .modal-body {
                margin-top: -5px;
            }

            .image-group {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-bottom: 2rem;

                img {
                    max-width: 100%;

                    @media ${device.tablet} {
                        max-width: 50%;
                    }
                }
            }
        }

`;

export const ModalPopupInnerStyled = styled(TitleWithTextStyled)`

    .modal-title {

        .container {
            text-align: left;

            @media ${device.tablet} {
                text-align: center;
            }

            .icon {
                width: 100px;
                height: auto;
            }
        }

        .title {
            font-weight: 500;
            margin-bottom: 0;
            margin-top: ${props => props.headImage ? '2rem' : '0'};
            color: ${palette.redDanger};
            line-height: 1.8rem;
            font-size: ${palette.fontSizeMd};
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;

            @media ${device.tablet} {
                margin-top: ${props => props.headImage ? '2rem' : '1rem'};;
                justify-content: center;
            }

            .alert-modal-icon {

                path.triangle {
                    fill: ${palette.white};
                    stroke: ${palette.redDanger};
                    stroke-width: 10px;
                }

                .sign path {
                    fill: ${palette.redDanger};
                }

            }

        }
    }

    .body {

        .container {
            text-align: center;


            .title {
                text-transform: none;
                margin-bottom: 1rem;
                text-align: left;
                font-size: ${palette.fontSizeMdl};
                color: ${palette.cta};
                /* display: ${props => props.name === 'scam-alert' ? 'none' : 'block'}; */

                @media ${device.tablet} {
                    text-align: center;
                }
            }

            p {
                text-align: left;
            }

            ul {
                padding-left: 20px;
                text-align: left;
                margin-top: 0;

                @media ${device.tablet} {
                    padding-left: 40px;
                }

                li {
                    text-align: left;
                    margin-bottom: 1rem;
                }
            }

            em {
                color: ${palette.redDanger};
                font-style: normal;
            }
        }

    }
`;
