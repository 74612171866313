import styled from 'styled-components'
import { device } from '../styles/device';




export const LayoutStyled = styled.div`

    main {
        @media ${device.tablet} {
            margin-top: 0;
        }
    }
`;