import PropTypes from "prop-types"
import React, { Component } from "react"
import Brand from './brand'
import Nav from './nav'
import BurgerAnimate from './burgerAnimate'
import {HeaderStyled, LogoStyled, NavStyled, HeadWrapStyled} from './headerStyled'
import Telephone from './icons/telephone'
import Email from './icons/emailSolid'
import FaceBook from './icons/fb'



class Header extends Component {
  constructor(props) {
    super();
    this.state = {
      isClickedBurger: false,
      isScrolled: false
     };

    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  handleScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    let scroll = false
    if(winScroll !== 0) {
      scroll = true
    }
    this.setState({ isScrolled: scroll })
  }
  handleClick() {
    this.setState({ isClickedBurger: !this.state.isClickedBurger })
  }

  render () {
    const { isScrolled } = this.state
    return (
        <HeadWrapStyled scrollClass={isScrolled} hideContact={this.props.hideContact}>
          <div className="header-top">
            <div className="header-top-wrapper">
              <div className="emailWrapper">
                <Email name="emailHeadBar"/><span className="text">info@savie-glove.com</span>
              </div>
              <div className="phoneWrapper">
                <Telephone name="telephoneHeadBar"/><span className="text">+662 023 0639</span>
              </div>
              <div className="fbWrapper">
              <a href={`https://www.facebook.com/SAVIE-GLOVE-By-Savie-Group-Corp-HkTh-104116648188475/`} target="_blank" rel="noopener noreferrer" className="fb-link"><FaceBook name="fbHeadbar"></FaceBook></a>
              </div>
            </div>
          </div>
          <HeaderStyled isClickedBurger={this.state.isClickedBurger} scrollClass={isScrolled} backgroundColor={this.props.backgroundColor}>
            <div className="header-inner">
              <LogoStyled scrollClass={isScrolled}>
                <Brand />
                <BurgerAnimate isClickedBurger={this.state.isClickedBurger} onClick={this.handleClick} />
              </LogoStyled>
              <NavStyled>
                <Nav />
              </NavStyled>
            </div>
          </HeaderStyled>

      </HeadWrapStyled>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  hideContact: PropTypes.bool,
  backgroundColor: PropTypes.bool
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
