import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import {LayoutStyled} from './layoutStyled'
import Footer from './footer'
import GoToTop from './goToTop'
import Helmet from "react-helmet"
import MarketingPopup from './marketingPopup'
import ModalPopup from './modalPopup'




const Layout = ({ children, hideContact, backgroundColor, showOverlay, showMarketingPopup, modalAlert }) => {

  useEffect(() => {
    let alertStorage = localStorage.getItem("alertShow");
    const scamDiv = document.getElementById('scam')

    if(alertStorage && scamDiv) {
      scamDiv.style.display = 'none'
    }

      return () => {
      };
  });

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }

  `)

  let promotePopup
  if (typeof window !== 'undefined') {
    promotePopup = localStorage.getItem('promoteShowed');
  }

  return (
    <>
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900&display=swap" rel="stylesheet"></link>
      <link rel="stylesheet" href="https://use.typekit.net/sqr4juy.css"></link>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.2/css/solid.min.css"></link>
    </Helmet>
      <LayoutStyled showOverlay={showOverlay}>
        <Header siteTitle={data.site.siteMetadata.title} hideContact={hideContact} backgroundColor={backgroundColor}/>
        <main>{children}</main>
        <Footer />
        {showMarketingPopup && !promotePopup &&
          <MarketingPopup name="promote" />
        }
        {modalAlert &&
          <ModalPopup title="alert-scam-head" body="alert-scam-body" id="scam" className="scam-alert" headImage={true} name="scam-alert" doNotShowAgainBox={true} />
        }
        <GoToTop />
      </LayoutStyled>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideContact: PropTypes.bool,
  showMarketingPopup: PropTypes.bool,
  modalAlert: PropTypes.bool
}

export default Layout
