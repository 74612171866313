

import React from "react"


const FaceBook = ({name}) => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="285px" height="286.617px" viewBox="99.5 -322.367 285 286.617" className={name}>
<g>
	<path fill="#4267B2" d="M363.535-315.676H120.451c-8.322,0.004-15.07,6.756-15.068,15.081v243.084
		c0.004,8.323,6.756,15.071,15.082,15.069h243.07c8.328,0.002,15.08-6.748,15.082-15.076c0-0.002,0-0.004,0-0.006v-243.083
		C378.613-308.931,371.861-315.678,363.535-315.676z"/>
	<path fill="#FFFFFF" d="M294.031-42.441v-105.665h35.623l5.338-41.358h-40.961v-26.342c0-11.946,3.318-20.087,20.447-20.087h21.713
		v-36.892c-3.777-0.502-16.736-1.626-31.818-1.626c-31.486,0-53.033,19.212-53.033,54.509v30.438h-35.488v41.358h35.488v105.665
		H294.031z"/>
</g>
</svg>

    )
}

export default FaceBook
