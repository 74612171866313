import React from 'react'
import { Link } from "gatsby"
import LogoSavie from './icons/logo_savie'


const Brand = () => {
    return (
        <Link to="/" style={{ display: 'block' }} className="logoWrap">
            <LogoSavie name="logo-savie"/>
        </Link>
    )
}

export default Brand