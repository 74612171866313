import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import {ButtonCtaStyled} from './buttonCtaStyled'

const ButtonCta = ({ text, className, iconButton, linkTo, isCta }) => {
  return (
    <ButtonCtaStyled className={`${className}`} isCta={isCta}>
      <Link to={`${linkTo}`} role='button'>
          {text}
        </Link>
    </ButtonCtaStyled>
    )
}

ButtonCta.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconButton: PropTypes.object,
  linkTo: PropTypes.string.isRequired,
  isCta: PropTypes.bool.isRequired
}

export default ButtonCta
