import React from "react"


const BellIcon = ({name}) => {
    return ( <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 304.43 286.07" className={name}>
            <path className="bell" stroke="#FFFFFF" strokeWidth="2" d="M269.16,203.63c-21.25-9.92-33.55-13.57-33.55-37.41
            c0-14.05,0.01-28.11,0-42.16c-0.04-46.44-20.87-74.77-62.42-87.66c-2-5.57-3.12-12.73-4.88-12.89c-10.55-0.96-21.29-0.77-31.84,0.31
            c-1.5,0.15-2.3,7.22-3.82,12.46c-42.47,13.36-63.29,41.8-63.34,88.84c-0.01,12.91-0.62,25.87,0.14,38.73
            c1.14,19.29-12,35.33-34.18,38.87c0,9.03,0,16.88,0,25.19c71.57,0,162.42,0,233.89,0C269.16,219.28,269.16,211.45,269.16,203.63z"/>
            <path className="bell-ball" d="M102.15,228.38c33.3,0,66.62,0,99.91,0c2.74,28.69-18.27,52.08-47.31,53.13C123.94,282.62,101.52,260.36,102.15,228.38z"/>
        </svg>

    )
}

export default BellIcon
