

export const addEventScroll = (handleScroll) => {
    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', handleScroll)
        const smoothscroll = require("smoothscroll-polyfill")
        smoothscroll.polyfill();
    }
}


