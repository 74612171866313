export const white = '#ffffff';
export const lightGreyBorder = 'rgba(255,255,255,.5)';
export const lightGreyLine = '#efecec';
export const black = '#000000';
export const mediumGrey = '#aaa';
export const cta = '#223d98';
export const ctaLight = '#2a49b1';
export const pinkButton = '#eb054e';
export const blue = '#26a7bd';
export const lightBlue = '#c9e8ee';
export const darkBlue = '#007AFF';
export const greyTransparent = 'rgba(168,165,165, .5)';
export const darkGrey = '#555556';
export const superDarkGrey = '#191a1b';
export const grey = '#26262b';
export const blueGrey = '#c0cfd8';
export const pinkGrey = '#ccb1be';
export const darkBlueGrey = '#1e2024';
export const purple = '#850dd0';
export const lightBg = '#ebedef';
export const lighterBg = '#f1f0eca3';
export const darkOnLight = '#b7b29da3';
export const whiteBlue = '#f6f6f8';
export const gradient = `linear-gradient(120deg, ${cta},#2badcd)`;
export const gold = '#AB8D3F';
export const borderGrey = '#ddd';
export const textBg = '#e6e8e8';
export const colorPickBlue = '#0caffd';
export const colorPickWhite = '#fff';
export const colorPickBlack = '#000';
export const colorPickPurple = '#8148a5';
export const lightText = '#827e7e';
export const redDanger = '#b31010';
export const success = '#008000';



export const fontBold = '700';

export const fontSizeXs = '0.68rem';
export const fontSizeSsm = '0.75rem';
export const fontSizeSm = '0.875rem';
export const fontSizeMd = '1rem';
export const fontSizeMdl = '1.2rem';
export const fontSizeReg = '1.5rem';
export const fontSizeLg = '1.8rem';
export const fontSizeXl = '3rem';
export const fontSizeTitle = '4rem';
export const fontSizeXxl = '5rem';

export const fontWeightThick = '700';
export const fontWeightTitle = '500';
export const fontWeightText = '400';

export const letterSpacingSm = '1px';

export const lineHeightXs = '1.2rem';
export const lineHeightSm = '1.6rem';
export const lineHeightMd = '1.8rem';
export const lineHeightLg = '2.2rem';


export const headerHeight = '100px';
export const headerWithTopHeight = '140px';
export const headerTabletHeight = '60px';

export const textShadowGrey = '0 0 10px rgba(0,0,0,.1)'
export const textShadowDarkGrey = '0 0 10px rgba(0,0,0,.7)'

export const fontFamilyMain = `'Poppins', sans-serif`;



