import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {TitleWithTextStyled} from './titleWithTextStyled'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'




const TitleWithText = ({slug, isLightText, className, icon}) => {
    const data = useStaticQuery(graphql`
    query TitleWithTextQuery {
        allContentfulTitleWithText {
            edges {
                node {
                    slug
                    title
                    text {
                        text
                        }
                    id
                }
            }
        }
    }
`)
if(!data.allContentfulTitleWithText.edges) {
    return <div>Loading...</div>
} else {
    return data.allContentfulTitleWithText.edges.map((edge) => {
        if(edge.node) {
            if(edge.node.slug === slug) {
                return <TitleWithTextStyled key={edge.node.id} isLightText={isLightText} className={className}>
                        <div className="container">
                            {edge.node.title && <h2 className="title">{icon && <span className="icon">{icon}</span>}{edge.node.title}</h2>}
                            <Markdown source={edge.node.text && edge.node.text.text} />
                        </div>
                    </TitleWithTextStyled>
                }

            }
            return true;
        })
    }
}

TitleWithText.propTypes = {
    slug: PropTypes.string.isRequired,
    isLightText: PropTypes.bool.isRequired,
    className: PropTypes.string,
    icon: PropTypes.object
  }

export default TitleWithText

