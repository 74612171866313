const size = {
    mobile: '576px',
    tablet: '768px',
    laptop: '992px',
    desktop: '1200px',
    wideDesktop: '1500px',
    jumboScreen: '2200px'
}

export const device = {
    mobile: `(min-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    desktop: `(min-width: ${size.desktop})`,
    wideDesktop: `(min-width: ${size.wideDesktop})`,

    lessThanDesktop: `(max-width: ${size.desktop})`
}


export const width = {
    sm: '540px',
    md: '750px',
    lg: '970px',
    xl: '1170px',
    xxl: '1440px',
    xxxl: '1900px'
}