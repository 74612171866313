import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';


export const HeadWrapStyled = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 3;

    .header-top {
        /* background-color: ${palette.lightBg}; */
        display: none;
        border-bottom: 1px solid ${palette.lightGreyLine};
        background-color: ${palette.white};
        transition: all .4s ease-in-out;

        @media ${device.tablet} {
            display: ${props => props.scrollClass || props.hideContact ? 'none' : 'block'};
            /* display: block; */
        }

        .header-top-wrapper {
            height: 40px;
            max-width: ${width.xxl};
            margin: 0 auto;
            display: flex;
            justify-content: flex-end;
            color: ${palette.black};
            font-size: ${palette.fontSizeSsm};
            font-weight: 400;
            padding-right: 15px;
            padding-left: 15px;

            @media ${device.desktop} {
                padding-right: 0;
                padding-left: 0;

            }
        }

        .emailWrapper, .phoneWrapper, .fbWrapper {
            display: flex;
            align-items: center;
            margin-left: 2rem;

            .fb-link {
                display: flex;
            }

            .fbHeadbar {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }

        .emailHeadBar, .telephoneHeadBar {
            width: 15px;
            height:20px;
            margin-right: .5rem;

            path {
                fill: ${palette.darkGrey};
            }
        }



        .fbHeadbar path {
            /* fill: #3B5999; */
        }
    }
`;

export const HeaderStyled = styled.header`
    width: 100%;
    background-color: ${props => props.backgroundColor ? palette.lightBg : palette.white};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow:  ${props => props.scrollClass ? '0 0 20px rgba(0,0,0,.3)' : '0 1px rgba(0,0,0,.2);'};
    border-bottom: 1px solid ${palette.lightGreyLine};
    /* transition: height .4s ease, margin .4s ease, opacity .2s .2s ease; */
    transition: all .4s ease;

    @media ${device.desktop} {
        padding-left: 50px;
        padding-right: 50px;
    }

    @media ${device.tablet} {
        flex-direction: row;
        height: ${props => props.scrollClass ? '60px' : palette.headerHeight};
        border-bottom: none;
    }

    .header-inner {
        justify-content: space-between;

        @media ${device.tablet} {
            width: ${width.xxl};
            display: flex;
            margin: 0 auto;
        }
    }

    ul {
        display: block;
        overflow: hidden;
        width: 100%;
        padding-left: 0;
        height: ${props => props.isClickedBurger ? 'auto' : '0'};
        transition: height .4s ease,margin .4s ease,opacity .2s .2s ease;

        @media ${device.tablet} {
            margin-top: ${props => props.scrollClass ? '10px' : '20px'};
        }

            li {
                padding-top: 20px;
                padding-bottom: 20px;
                transition: height .4s ease,margin .4s ease,opacity .2s .2s ease;

                @media ${device.tablet} {
                    /* padding-bottom: ${props => props.scrollClass ? '0px' : '10px'};
                    padding-top: ${props => props.scrollClass ? '0px' : '20px'}; */
                    padding-top: 0;
                    padding-bottom: 0;
                }

                &.icon {
                    padding-top: 16px;
                    padding-bottom: 16px;

                    @media ${device.tablet} {
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }

            li:not(:last-child) {
                border-bottom: 1px solid ${palette.lightGreyLine};
                margin-right: 1rem;

                @media ${device.tablet} {
                    border-bottom: none;
                }

                @media ${device.laptop} {
                    margin-right: 4rem;
                }
            }

            li a {
                color: ${palette.black};
                font-weight: 400;
                font-size: ${palette.fontSizeSsm};
                white-space: nowrap;
                text-transform: uppercase;

                @media ${device.tablet} {
                    color: ${palette.black};
                }

                &.active {
                    color: ${palette.cta};
                    font-weight: 700;
                }
            }

            li a svg {
                font-size: ${palette.fontSizeMd};
                /* margin-bottom: -3px; */
            }


        @media ${device.tablet} {
            display: flex;
            height: auto;
        }
    }

`;

export const LogoStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: none;
    padding-top: 5px;
    padding-right: 0;
    padding-bottom: 5px;
    transition: height .4s ease,margin .4s ease, opacity .2s .2s ease;
    height: ${props => props.scrollClass ? '50px' : '60px'};

    @media ${device.tablet} {
        margin-right: 3rem;
        height: unset;
    }

    @media ${device.laptop} {
        margin-right: 8rem;
    }

    svg.logo-savie {
        transition: width .4s ease, height .4s ease,margin .4s ease,opacity .2s .2s ease;
        width: ${props => props.scrollClass ? '80px' : '130px'};
        padding-right: ${props => props.scrollClass ? '0' : '20px'};

        @media ${device.tablet} {
            padding-right: 0;
        }

        .savie path {
            fill: ${palette.cta};
        }

        .symbol path, .symbol polygon {
            fill: ${palette.gold};
        }
    }


`;

export const NavStyled = styled.div`
    display: flex;
    align-items: center;
`;
