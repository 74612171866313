import React from "react"


const Close = ({name}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="212px" height="209px" viewBox="118.5 -298.5 212 209" className={name}>
<g>
	<g>
		<path fill="#FFFFFF" d="M316.495-128.815c3.667,3.692,3.667,9.714,0,13.379L303.062-102c-3.664,3.665-9.664,3.665-13.38,0
			L224.5-167.181L159.317-102c-3.717,3.665-9.718,3.665-13.38,0l-13.435-13.436c-3.665-3.665-3.665-9.66,0-13.379l65.183-65.182
			l-65.183-65.18c-3.665-3.706-3.665-9.704,0-13.396l13.435-13.406c3.662-3.694,9.663-3.694,13.38,0l65.183,65.179l65.182-65.179
			c3.716-3.694,9.716-3.694,13.38,0l13.434,13.406c3.667,3.691,3.667,9.69,0,13.396l-65.177,65.18L316.495-128.815z"/>
	</g>
</g>
</svg>

    )
}

export default Close
