import React, {useState, useEffect, useRef, onIntersect } from "react"
import ScrollHook from './helper/scrollHook'
import {GoToTopStyled} from './goToTopStyled'

const GoToTop = () => {
    const [divButton, setDivButton] = useState(null)

    const isScroll = ScrollHook()

    const ref = useRef(null)


    const getObserver = () => {
        if (ref.current === null) {
          ref.current = new IntersectionObserver(onIntersect);
        }
        return ref.current;
      }

    useEffect(() => {
        setDivButton(getObserver())

        return () => {

        };
    }, [])


    if(isScroll > 100) {
        divButton.classList.add('active')
    } else if (isScroll < 100) {
        if(divButton && divButton.className.search('active') > -1) divButton.classList.remove('active')
    }
    const handleClick = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <GoToTopStyled ref={ref} onClick={handleClick} />
    )
}

 export default GoToTop

