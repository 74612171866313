import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Markdown from 'react-markdown'
import Nav from './nav'
import {FooterStyled, FooterNavStyled} from './footerStyled'
import Brand from './brand'
import FaceBook from './icons/fb'




const Footer = () => {
    const data = useStaticQuery(graphql`
    query FooterQuery {
        allContentfulFooter {
            edges {
                node {
                  id
                  textFooter
                  text {
                    text
                  }
                }
              }
        }
    }
`)
if(!data.allContentfulFooter.edges) {
    return <div>Loading...</div>
} else {
    return data.allContentfulFooter.edges.map((edge) => {
        if(edge.node && edge.node.text) {
            return (
                <FooterStyled key={edge.node.id} id="footer">
                    <div className="container" id="footerContainer">  
                        <div className="fbWrapper">
                            <a href={`https://www.facebook.com/SAVIE-GLOVE-By-Savie-Group-Corp-HkTh-104116648188475/`} target="_blank" rel="noopener noreferrer" className="fb-link"><FaceBook name="fbFoot"></FaceBook></a>
                        </div>
                        <div className="logo-container">
                            <Brand />
                        </div>
                        <div className="textWrap">
                            <Markdown source={edge.node.text.text} />
                        </div>
                        <FooterNavStyled className="navFooter">
                            <Nav />
                        </FooterNavStyled>
                    </div>
                </FooterStyled>
            )
        }
        return true;
    })
}
}
 export default Footer

