import styled from 'styled-components'
import * as palette from '../styles/variables'


export const TitleWithTextStyled = styled.div`
    color: ${palette.black};
    text-align: center;

    .title {
        text-align: center;
        margin-bottom: 0;
        font-weight: 600;
        color: ${props => props.isLightText ? palette.white : palette.cta};
        font-family: ${palette.fontFamilyMain};
        font-size: ${palette.fontSizeMdl};
        text-transform: uppercase;
        max-width: 100%;
        display: inline-block;
        margin-bottom: 2rem;
        line-height: 1.6rem;

        .icon {
            width: 30px;
            height: 20px;
            display: inline-block;
            margin-right: 5px;

            svg {
                max-width: 100%;
                height: 100%;
            }
        }
    }

    p {
        margin-bottom: 0;
        line-height: ${palette.lineHeightMd};
        color: ${palette.darkGrey};
    }

`;

