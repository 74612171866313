import React, {useEffect, useState} from "react"
import {MarketingPopupStyled} from './marketingPopupStyled'
import Close from './icons/close'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import Modal from "react-bootstrap/Modal";
import { useStaticQuery, graphql } from "gatsby"



const MarketingPopup = ({name}) => {
    const [show, setShow] = useState(true)

    const handleClose = () => {
        const scamAlertPopup = document.getElementById('scam')
        let promoteShowed = localStorage.getItem('promoteShowed');

        if(!promoteShowed) {
            localStorage.setItem("promoteShowed", true)
            scamAlertPopup && scamAlertPopup.classList.remove('hide')
        }
        setShow(false);
    }

    const data = useStaticQuery(graphql`
    query PopupManagerQuery {
      allContentfulPopupManager {
        edges {
          node {
            backgroundUrl
            buttonText
            id
            name
            position
            showCloseButton
          }
        }
      }
    }
  `)

    if(!data.allContentfulPopupManager.edges) {
        return <div>Loading...</div>
    } else {
        return data.allContentfulPopupManager.edges.map((edge) => {
            if(edge.node && edge.node.name !== 'default' && typeof window !== 'undefined') {
                let promotePopup = localStorage.getItem('promotePopup');
                const scamAlertPopup = document.getElementById('scam')
                scamAlertPopup && scamAlertPopup.classList.add('hide')
                let promoteShowed = localStorage.getItem('promoteShowed');

                if(!promotePopup) {
                    localStorage.setItem("promotePopup", true)
                }
                if(promoteShowed) {
                    scamAlertPopup && scamAlertPopup.classList.remove('hide')
                }
                const img = edge.node.backgroundUrl && edge.node.backgroundUrl
                return (
                    <MarketingPopupStyled key={edge.node.id}>
                        <Modal show={show}
                                onHide={handleClose}
                                aria-labelledby="contained-modal-title-vcenter"
                                className="promoteModal"
                                >
                           <Modal.Header closeButton className="promoteModalHead">
                            </Modal.Header>
                            <Modal.Body className="promoteModalBody">
                            {img && <div className="imageWrapper">
                                <img src={img} alt={edge.node.name} />
                            </div>}
                            </Modal.Body>
                        </Modal>
                    </MarketingPopupStyled>
                )
            }
                return
        })
    }
}

MarketingPopup.propTypes = {
    name: PropTypes.string.isRequired,

}
 export default MarketingPopup

