import React from "react"


const Telephone = ({name}) => {
    return (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="285px" height="286.617px" viewBox="99.5 -322.367 285 286.617" className={name}>
<g>
	<path fill="#FFFFFF" d="M116.233-199.23c-4.71-12.7-8.121-25.66-7.081-39.44c0.649-8.485,3.833-15.729,10.036-21.672
		c6.756-6.374,13.139-13.073,19.781-19.553c8.639-8.542,19.504-8.485,28.16,0c5.344,5.237,10.638,10.588,15.932,15.939
		c5.133,5.133,10.313,10.265,15.443,15.454c9.03,9.126,9.096,19.771,0.066,28.85c-6.496,6.48-12.929,13.024-19.521,19.399
		c-1.721,1.673-1.883,3.078-0.958,5.189c4.32,10.369,10.589,19.61,17.621,28.25c14.146,17.41,30.142,32.853,49.206,44.79
		c4.11,2.55,8.607,4.434,12.862,6.757c2.21,1.201,3.672,0.812,5.458-1.024c6.432-6.642,13.023-13.187,19.618-19.731
		c8.639-8.526,19.455-8.576,28.095,0c10.589,10.49,21.129,21.03,31.604,31.619c8.802,8.851,8.754,19.732-0.097,28.632
		c-6.01,6.058-12.327,11.791-17.946,18.108c-8.201,9.175-18.595,12.213-30.369,11.563c-17.183-0.926-33.015-6.642-48.297-14.048
		c-33.927-16.484-62.899-39.334-87.162-68.192C140.756-149.73,125.944-172.97,116.233-199.23z M375.036-179.889
		c0-72.885-59.342-132.211-132.227-132.211v25.18c59.018,0,107.055,48.031,107.055,107.031H375.036z M302.639-179.889h25.171
		c0-46.845-38.146-84.984-85-84.984v25.172c16.013,0,31.018,6.221,42.322,17.507C296.418-210.899,302.639-195.877,302.639-179.889z"
		/>
</g>
</svg>
    )
}

export default Telephone
