import React, {useEffect} from "react"
import {ModalPopupStyled, ModalPopupInnerStyled} from './modalPopupStyled'
import Close from './icons/close'
import TitleWithText from './titleWithText'
import PropTypes from 'prop-types'
import fraudAlert from '../images/fraud-alert-man.jpg'
import fakeAll from '../images/fake-all.jpg'
import { Link } from "gatsby"




const ModalPopup = ({id, title, body, className, headImage, name, doNotShowAgainBox}) => {
    const handleClickInputCheck = () => {
        const inputCheck = document.getElementById('doNotShowBox')
        let alertStorage = localStorage.getItem("alertShow");
        if(inputCheck.checked && !alertStorage) {
            localStorage.setItem("alertShow", true)
        } else {
            localStorage.removeItem("alertShow")
        }
    }
    const handleClose = () => {
        const madalName = document.querySelectorAll(`#${id}`)
        madalName && madalName[0].classList.add('hide')
    }
    const headImageClass = headImage ? 'headImage' : ''
    return (
        <ModalPopupStyled id={id} className={`${className}`}>
            <div className="modal-sandbox" onClick={handleClose}></div>
            <div className="modal-box">
                <div className={`modal-header ${headImageClass}`}>
                    <div className="closeWrapper" onClick={handleClose}>
                        <Close name={`close-modal`} />
                    </div>
                    {/* <img src={fraudAlert} alt="savie-glove-warning" /> */}
                </div>
                <div className="modal-body">
                <TitleWithText slug={title} isLightText={false} className="fraud-alert-title"/>
                {/* {headImage &&
                <div className="image-group">
                    <img className="fake" src={fakeAll} alt="fake" />
                </div>} */}
                    <ModalPopupInnerStyled name={name}>
                        <TitleWithText slug={body} isLightText={false} className="body"/>
                    </ModalPopupInnerStyled>
                    <br />
                    <div className="doNotShowWrapper">
                        <form id="form">
                            <input type="checkbox" id="doNotShowBox" name="doNotShowBox" value="check" onClick={handleClickInputCheck}/>
                            <label htmlFor="doNotShowBox"> Do not show this again</label>
                        </form>
                    </div>
                    <div className="btnWrapper">
                        <button className="btn read-more"><Link to={`/fraud-alert/`} className="text">Learn more</Link></button>
                        <button className="btn close-modal" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </ModalPopupStyled>
    )
}

ModalPopup.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    headImage: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    doNotShowAgainBox: PropTypes.bool
}
 export default ModalPopup

