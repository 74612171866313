import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device } from '../styles/device';




export const ButtonCtaStyled = styled.div`
    display: block;

    &.heroLanding {

        a {
            margin: 0 auto;

            @media ${device.laptop} {
                margin: 0;
            }
        }
    }

    a {
        color: ${props => props.isCta ? palette.white : palette.cta};
        font-size: ${palette.fontSizeSm};
        font-weight: 500;
        padding: 1rem 1.6rem;
        margin-top: 0;
        background-color: ${props => props.isCta ? palette.cta : 'transparent'};
        border: 1px solid ${props => props.isCta ? 'transparent' : palette.cta};
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 300px;
        margin: 0 auto;
        transition: all .4s;
        white-space: nowrap;

        @media ${device.tablet} {
            padding: .8rem 2rem;
        }

        svg {
            margin-left: 1rem;
            font-size: 0.9rem;
            transition: transform 0.2s;
        }

        &:hover {
            background-color: ${palette.ctaLight};
            color: ${props => props.isCta ? palette.white : palette.white};
        }

        &:hover svg {
            transform: translateX(70%);
        }
    }

`;