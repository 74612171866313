import styled from 'styled-components'
import * as palette from '../styles/variables'
import { width } from '../styles/device'
import { lighten } from 'polished'



export const FooterStyled = styled.footer`
    background-color: ${palette.cta};
    padding: 2rem 15px 2rem 15px;

    .container {
        max-width: ${width.sm};
        margin: 0 auto;

        .logo-container {
            display: flex;
            justify-content: center;
        }

        .logoWrap {
            text-align: center;
            display: inline-block;

            svg {
                max-width: 80px;
            }
        }
    }

    .fbWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        .fb-link {
            display: flex;
        }

        .fbFoot {
            width: 35px;
            height: 35px;
            cursor: pointer;
        }
    }


    .textWrap {
        text-align: center;
        color: ${palette.white};
        font-size: ${palette.fontSizeXs};
        line-height: ${palette.lineHeightXs};
        margin-top: 1rem;

         p {
            margin-bottom: 1rem;
         }
    }

`;

export const FooterNavStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid ${lighten(0.3, palette.cta)};
    padding-top: 1rem;

    ul {
        padding-left: 0;
        text-align: center;
    }
    ul li {
        font-size: ${palette.fontSizeXs};
        display: inline-block;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        a {
            color: ${palette.white} !important;

            &.active {
                font-weight: 700;
            }

            &.fraud {
                svg {
                    display: none;
                }
            }
        }

    }

`